import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { Box, Paper, TableContainer, CircularProgress, Typography, Popover } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import {  GridActionsCellItem, GridColumnVisibilityModel, GridRowId, GridRowSelectionModel, MuiEvent, DataGridPro, GridColDef, GridCellEditStartParams, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro';
import LoadingIcon from '@mui/icons-material/Loop';
import EditIcon from '@mui/icons-material/Edit';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { httpsCallable } from 'firebase/functions';
import { doc, onSnapshot, collection, addDoc, updateDoc, query, where, getDocs, collectionGroup } from 'firebase/firestore';

import moment from 'moment';

import { showSuccessToast, showInfoToast, showErrorToast, getError, isValidAction, actionTypes } from '../utils';
import { FirebaseContext } from '../firebase/FirebaseContext';
import { DashboardForm } from '../dashboard/DashboardForm';
import { ActionsBar } from '../dashboard/ActionsBar';


export const SmallDatagrid: React.FC<any> = ({ requestedRoom, reservations }) => {
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState<GridColumnVisibilityModel>({
    reservation_id: false,
  });
  const columns: GridColDef[] = [
    {
      field: 'reservation_id',
    },
    {
      field: 'reservation_location',
      headerName: 'Location',
      flex: 0.5,
      editable: false,
    },
    {
      field: 'reservation_status',
      headerName: 'Status',
      flex: 0.5,
      editable: false,
    },
    {
      field: 'reservation_room_name',
      editable: false,
      headerName: 'Reserved Room',
      flex: 0.5
      // width: 20,
      // renderCell: (params) => {
      //   return params.row.is_archived ? <Visibility fontSize='small' /> : <EditIcon fontSize='small' />
      // }
    },
    {
      field: 'requested_room',
      headerName: 'Requested Room',
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        return requestedRoom || 'Any Room Type'
      }
    },
  ];
  return (
    <Box sx={{ height: 'auto', width: '100%' }}>
      <DataGridPro
        rows={reservations}
        columns={columns}
        hideFooter
        getRowId={(row) => row.reservation_id}
        disableRowSelectionOnClick
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
      />
    </Box>
  );
}

export const WaitlistDatagrid: React.FC<any> = ({ venues }) => {
  const [editState, setEditState] = useState<any>({});
  const [recordList, setRecordList] = useState<any[]>([]);
  const [alertSending, setAlertSending] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popoverData, setPopoverData] = useState<ReactElement | null>(null);
  const [activeData, setActiveData] = useState<any[]>([]);
  const [historicalData, setHistoricalData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showHistorical, setShowHistorical] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<string[]>([]);
  const [canDelete, setCanDelete] = useState<string[]>([]);
  const [filterBooked, setFilterBooked] = useState<boolean>(false);
  const [venueDetail, setVenueDetail] = useState<any>({});
  const { db, currentManager, functions } = useContext(FirebaseContext);
  const apiRef = useGridApiRef();

  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
  React.useState<GridColumnVisibilityModel>({
    doc_id: false,
    venue_uid: false
  });


  React.useEffect(() => {
    if (!apiRef || !apiRef.current || !apiRef.current.getSelectedRows) { return }    
    const rows = apiRef.current.getSelectedRows();
      setSelectedRows(rowSelectionModel.map(x => rows.get(x)))

  }, [rowSelectionModel, apiRef]);

  React.useEffect(() => {
    (async () => {
      const venueQuery = query(collection(db, 'venues'), where('venue_name', '!=', 'null'))
      const venuesSnap = await getDocs(venueQuery);
      const venueDataset = venuesSnap.docs.map(x => ({uid: x.id, ...x.data()}))
      const venueData = venueDataset.reduce((obj: any, x: any) => ({...obj, [x.uid]: {...x}}), {})
      setVenueDetail(venueData);
    })()
  }, [db])

  useEffect(() => {
    if (!venues || venues.length === 0) { return }
    const venueIds = venues.map((x: any) => x.uid || x);
    setCanEdit(venueIds.filter((x: any) => isValidAction(actionTypes.EDIT_WAITLIST_REQUEST, currentManager, x)));
    setCanDelete(venueIds.filter((x: any) => isValidAction(actionTypes.DELETE_WAITLIST_REQUEST, currentManager, x)));
    setColumnVisibilityModel({
      doc_id: false,
      venue_uid: venues && venues.length > 1
    })
    const unsub = onSnapshot(
      collectionGroup(db, 'waitlist'),
      (res) => {
        const venueUids = venues.map((x: any) => x.uid || x);
        const actives:any[] = [];
        const archived:any[] = [];
        res.forEach(doc => {
          const parentId = doc.ref.parent.parent?.id
          if (venueUids.includes(parentId)) {
            const data = doc.data()
            if (data && data.is_archived) {
              archived.push({doc_id: doc.id, venue_uid: parentId, ...data});
            } else if (data && !data.is_archived) {
              actives.push({doc_id: doc.id, venue_uid: parentId, ...data});
            }
          }
        })
        setHistoricalData(archived || [])
        setActiveData(actives || []);
      }
    );
    return () => unsub();
  }, [currentManager, db, venues])

  useEffect(() => {
    const data = showHistorical ? activeData.concat(historicalData) : activeData
    setRecordList(filterBooked ? (data || []).filter(x => isReserved(x)) : (data || []))
  }, [activeData, historicalData, showHistorical, filterBooked])
  
  const isReserved = (waitlist: any) => {
    if (Object.keys(waitlist?.reservations || {}).length <= 0) {
      return false;
    }

    for (const reservation of Object.values(waitlist.reservations)) {
      if (!['cancelled', 'canceled', 'noshow'].includes(
        (reservation as any)?.reservation_status?.toLowerCase()
      )) {
        return true;
      }
    }

    return false;
  };

  const handleUpdateWaitlistData = useCallback(
    async (venue_uid: string, payload: any) => {
      try {
        const waitlistRef = collection(db, 'venues', venue_uid, 'waitlist');
        const q = query(waitlistRef, where('id', '==', payload.id));
        const waitlistSnap = await getDocs(q);
        if (waitlistSnap.docs.length > 0) {
          const item = waitlistSnap.docs[0]
          await updateDoc(doc(db, 'venues', venue_uid, 'waitlist', item.id), payload);
        } else {
          await addDoc(waitlistRef, payload);
        }
        showInfoToast('Guest record successfully updated');
      } catch (e) {
        console.error('Error updating guest record', e);
        showErrorToast(getError(e).message)
      }
    },
    [db]
  );

  const handleRowAction = React.useCallback((docUid: GridRowId) => () => {
        setEditState(activeData.concat(historicalData).find((i) => i.doc_id === docUid));
        setIsOpen(true);
    },[activeData, historicalData]
  );

  const handleNotify = React.useCallback((docUid: GridRowId) => () => {
      if (docUid && !alertSending) {
        setAlertSending((docUid || '').toString());
        const waitlistRow = activeData?.find((i) => i.doc_id === docUid);
        if (!waitlistRow || !waitlistRow.id) {
          return;
        }
        const sendAvailabilityNotifications = httpsCallable(functions, 'notification-sendAvailabilityNotifications');
        sendAvailabilityNotifications({ venueDocId: waitlistRow.venue_uid, waitlistUuid: waitlistRow.id })
        showSuccessToast('Successfully sent availability alerts')
        setAlertSending('');
      }
    },
    [alertSending, activeData, functions]
  );
  // const makeCamelCase = (str: string) => str.split(' ').map((e,i) => i ? e.charAt(0).toUpperCase() + e.slice(1).toLowerCase() : e.toLowerCase()).join(' ')

  const getAltRoomDescription = React.useCallback((row: any) => {
    let retValue = '';
    if (!venueDetail || !venueDetail[row.venue_uid]) { return retValue }

    const nonCancelled = Object.values(row.reservations || {}).filter((x: any) => x.reservation_status && !x.reservation_status.toLowerCase().startsWith('cancel'));
    // if (row.reservations && Object.keys(row.reservations).length > 1) {
      if (nonCancelled.length > 1) {
        return 'Multiple Reservations'
      }
    // }
    // console.log('NON CANCELLED', nonCancelled);
    const hasAlternateRoom = Object.values(nonCancelled).some((x: any) => x.reservation_alternate_room);
    const locations = Object.values(nonCancelled).map((x: any) => x.reservation_location);
    const statuses = Object.values(nonCancelled).map((x: any) => x.reservation_status);
    
    const cancelled = Object.values(row.reservations || {}).filter((x: any) => x.reservation_status && x.reservation_status.toLowerCase().startsWith('cancel'));

    if (hasAlternateRoom && locations?.length && !locations.includes((venueDetail[row.venue_uid].venue_name || ''))) { 
      // retValue = locations.length > 1 ? 'Multiple Locations' : locations[0];
      retValue = locations[0];
    } else {
      if (hasAlternateRoom && row.requested_room) {
        retValue = 'Alternate room'
      }
    }
    if (statuses?.length) {
      if (retValue.length > 1) {
        retValue += '\n'
      }
      retValue += statuses[0];
      // retValue += statuses.length > 1 ? 'Multiple Statuses' : statuses[0];
    } else if (cancelled?.length) {
      const cancelledStatuses = cancelled.map((x: any) => x.reservation_status);
      retValue += cancelledStatuses[0] || '';
    }
    return retValue
  }, [venueDetail]);

  const summaryText = React.useCallback((data: any) => {
    const nonCancelled = Object.values(data.reservations || {}).filter((x: any) => x.reservation_status && !x.reservation_status.toLowerCase().startsWith('cancel'));

    // const hasAlternateRoom = Object.values(nonCancelled).some((x: any) => x.reservation_alternate_room);
    // const statuses = Object.values(nonCancelled).map((x: any) => x.reservation_status);
    return (
      <>
          {data.vip_guest ? <div style={{textAlign: 'center'}}><Typography color="inherit" variant='h6'><b>VIP</b></Typography></div> : <></> }
          <Typography color="inherit" variant='h5'>{data.name}</Typography>
          <Typography color="inherit"><b>Phone{data.notify_phone ? '*' : ''}:</b> {data.phone}</Typography>
          <Typography color="inherit"><b>Email{data.notify_email ? '*' : ''}:</b> {data.email}</Typography>
          {/* {statuses?.length > 0 || hasAlternateRoom ? <Typography color="inherit"><b>Reservation Status:</b> {getAltRoomDescription(data)}</Typography> : <></>} */}
          {data.special_requests ? <Typography style={{whiteSpace: 'pre-line'}} color="inherit"><b>Special Requests:</b><br />{data.special_requests}</Typography> : <></>}
          {data.notes ? <Typography style={{whiteSpace: 'pre-line'}} color="inherit"><b>Notes:</b><br />{data.notes}</Typography> : <></>}
          {nonCancelled.length > 1 ? (
            <div style={{marginTop: 8}}>
              <Typography color="inherit" style={{fontWeight: 'bold'}}>Reservations:</Typography>
              <SmallDatagrid requestedRoom={data.requested_room} reservations={nonCancelled} />
            </div>
          ) : <></>}
      </>
    )
  }, []);

  const getDetailPanelContent = React.useCallback((params:GridRowParams) => {
    return (
      <div style={{padding: 8, minWidth: 200, maxWidth: '80%', border: 'solid 1px #ccc', margin: '4px 60px'}}>
        {summaryText(params.row)}
      </div>
    )
  }, [summaryText]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    const field = event.currentTarget.dataset.field!;
    const id = event.currentTarget.parentElement!.dataset.id!;
    const data = recordList.find(i => i.doc_id === id)
    if (data && field !== '__check__' && field !== '__detail_panel_toggle__' && field !== 'is_archived' && field !== 'actions') {
      setPopoverData(
        <React.Fragment>
          {summaryText(data)}
        </React.Fragment>
      )
      setAnchorEl(event.currentTarget);
    } else {
      setPopoverData(null);
      setAnchorEl(null);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverData(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'doc_id',
    },
    {
      field: 'is_archived',
      editable: false,
      headerName: '',
      width: 20,
      renderCell: (params) => {
        if (canEdit.length === 0 || !canEdit.includes(params.row.venue_uid)) { return <React.Fragment key={params.id} /> }
        return params.row.is_archived ? [
          <GridActionsCellItem
            key={params.id}
            icon={<Visibility fontSize='small' />}
            label="View"
            hidden={!params.row.is_archived}
            onClick={handleRowAction(params.id)}
          /> ] : [
          <GridActionsCellItem
            key={params.id}
            icon={<EditIcon fontSize='small' />}
            label="Edit"
            onClick={handleRowAction(params.id)}
          />
        ]
      }
    },
    {
      field: 'venue_uid',
      headerName: 'Venue',
      flex: 0.5,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
      editable: false,
      renderCell: (params) => {
        const nonCancelled = Object.values(params.row.reservations || {}).filter((x: any) => x.reservation_status && !x.reservation_status.toLowerCase().startsWith('cancel'));

        const hasNonCancelledReservations = Object.keys(nonCancelled).length > 0;
        const hasAlternateRoom = Object.values(nonCancelled).some((x: any) => x.reservation_alternate_room);
        return <div 
          style={{
            fontWeight: hasNonCancelledReservations  ? 'bold' : 'normal', 
            fontSize: hasNonCancelledReservations ? 'larger' : 'inherit', 
            color: hasAlternateRoom ? 'blue' : hasNonCancelledReservations ? 'green' : params.row.is_archived ? 'red' : 'inherit'}}>
              {params.row.name}{params.row.vip_guest ? <>
                <br />
                <span style={{fontWeight: 'bold', fontSize: '1.1rem'}}>
                  VIP
                </span></> 
              : <></>}
        </div>
      }
    },
    {
      field: 'adults',
      headerName: 'Adults/Children',
      flex: 0.4,
      editable: false,
      valueGetter: (params) => {
        return Number(params.row.adults || 0) + Number(params.row.children || 0)
      },
      renderCell: (params) => {
        return <div>
          {params.row.adults}/{params.row.children}
        </div>
      }
    },
    {
      field: 'rooms',
      headerName: 'Rooms',
      flex: 0.3,
      editable: false,
    },
    {
      field: 'requested_room',
      headerName: 'Request',
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        return params.value ? params.value.split(',').join(', ') : 'Any Room Type'
      },
    },
    {
      field: 'reservations',
      headerName: 'Reservation',
      flex: 0.5,
      editable: false,
      valueGetter: (params) => {
        return (getAltRoomDescription(params.row) || '').trim();
      },
      renderCell: (params) => {
        const hasReservations = Object.keys(params.row.reservations || {}).length > 0;
        const nonCancelled = Object.values(params.row.reservations || {}).filter((x: any) => x.reservation_status && !x.reservation_status.toLowerCase().startsWith('cancel'));
        const hasAlternateRoom = Object.values(nonCancelled).some((x: any) => x.reservation_alternate_room);
        return <div style={{
            fontWeight: nonCancelled.length > 0 ? 'bold' : 'normal', 
            whiteSpace: 'pre-line', 
            color: hasAlternateRoom ? 'blue' : nonCancelled.length > 0 ? 'green' : 'inherit'
          }}>
          {hasReservations || hasAlternateRoom ? getAltRoomDescription(params.row) : ''}
        </div>
      }
    },
    {
      field: 'checkin',
      headerName: 'Check-in',
      flex: 0.5,
      editable: false,
      valueGetter: (params) => {
        return params.value ? moment(params.value.toDate()).format('YYYY-MM-DD') : ''
      }
    },
    {
      field: 'checkout',
      headerName: 'Check-out',
      flex: 0.5,
      editable: false,
      valueGetter: (params) => {
        return params.value ? moment(params.value.toDate()).format('YYYY-MM-DD') : ''
      }
    },
    {
      field: 'last_notification_date',
      headerName: 'Last Notification',
      flex: 0.5,
      editable: false,
      valueGetter: (params) => {
        return params.value ? moment(params.value.toDate()).format('YYYY-MM-DD') : ''
      }
    },
    {
      field: 'create_date',
      headerName: 'Date Added',
      flex: 0.5,
      editable: false,
      valueGetter: (params) => {
        return params.value ? moment(params.value.toDate()).format('YYYY-MM-DD') : ''
      }
    },
    {
      field: 'reason_code',
      headerName: 'Reason',
      flex: 0.25,
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams<any>) => {
        return params.row.is_archived ? [] : [
          <GridActionsCellItem
            icon={!!alertSending && alertSending === params.id ? <LoadingIcon fontSize='small' /> : <NotificationsActiveIcon fontSize='small' />}
            label="Notify"
            disabled={!!alertSending && alertSending === params.id}
            onClick={handleNotify(params.id)}
          /> ]
      }
    },
  ]


  if (!venues || venues.length < 1 || !activeData) { 
    return <div style={{
      height: 400, 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      width: '100%'}}>
        <CircularProgress />
    </div>  
  }
  return (
    <>
      <ActionsBar
        venueList={venues.map((x: any) => x.uid || x)}
        venueUid={venues && venues.length === 1 ? venues[0]?.uid : null}
        records={recordList}
        selected={selectedRows}
        setSelected={setRowSelectionModel}
        handleAddGuest={() => {setEditState({}); setIsOpen(true)}}
        handleShowHistorical={(val:boolean) => setShowHistorical(val)}
        handleFilterBooked={(val:boolean) => setFilterBooked(val)}
      />
    <TableContainer component={Paper} sx={{display: 'table', width: '100%', padding: 0}}>
      <Box 
        sx={{ 
          width: '100%',
          '& .rz_bold': {
            fontWeight: 'bold',
          },
          height: 'calc(100vh - 300px)'
        }}>
          <DataGridPro   
            apiRef={apiRef}        
            disableColumnSelector={true}
            disableRowSelectionOnClick
            getRowId={(row) => row.doc_id}
            getRowHeight={() => {
              return 'auto';
            }}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={({ row }) => 'auto'} // Height based on the content.
            rowThreshold={0}
            checkboxSelection={canDelete.length > 0}
            rows={recordList} 
            columns={columns}
            pagination
            pageSizeOptions={[20, 50, 100]}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 }},
              sorting: {
                sortModel: [{ field: 'create_date', sort: 'desc' }],
              },
            }}
            slotProps={{
              cell: {
                onMouseEnter: handlePopoverOpen,
                onMouseLeave: handlePopoverClose,
              },
            }}
            onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
              event.defaultMuiPrevented = true;
            }}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            isRowSelectable={(params) => !params.row.is_archived && canDelete.includes(params.row.venue_uid)}
            columnVisibilityModel={columnVisibilityModel}
          />
          <Popover
            sx={{
              pointerEvents: 'none',
            }}
            open={!!anchorEl}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box sx={{p: 1, minWidth: 500}}>
              {popoverData}
            </Box>
          </Popover>
      </Box>
    </TableContainer>
    {isOpen && (
      <DashboardForm 
        isOpen={isOpen} 
        setIsOpen={(val:boolean) => {
          setEditState({});
          setIsOpen(val);
        }}         
        venueUid={editState?.venue_uid || (venues && venues.length === 1 ? venues[0].uid : null)}
        values={editState} 
        onAddToWaitlist={handleUpdateWaitlistData}
      />
    )}
    </>
  )
};
