import React, { useCallback, useContext } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../firebase/FirebaseContext';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import Home from '@mui/icons-material/Home';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { Help as HelpIcon } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Logout as LogoutIcon } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MyAccount } from './MyAccount';
import { SupportPage } from './Support';
import { VenueSettings } from './VenueSettings';
import { isVenueAdmin } from '../utils';
import { UserSettings } from './UserSettings';
import { UserDetail } from './UserDetail';
import { httpsCallable } from 'firebase/functions';
const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

const AdminMenuButton: React.FC<any> = ({itemName, Icon, label, activeMenu, navigate}) => {
  return (
    <ListItemButton style={
      {
        backgroundColor: activeMenu === itemName ? '#1976d2' : 'inherit',
        color: activeMenu === itemName ? '#fff' : 'inherit',
      }
    } onClick={() => {navigate(itemName)}}>
      <ListItemIcon style={{color: activeMenu === itemName ? '#fff' : 'rgba(0, 0, 0, 0.54)'}}>
        {Icon}
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  )
}

export const SettingsDashboard: React.FC<any> = () => {
  const { auth,functions, currentManager } = useContext(FirebaseContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [title, setTitle] = React.useState('');
  const [activeMenu, setActiveMenu] = React.useState('');
  const [authUserList, setAuthUserList] = React.useState<any[]>([]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getUserList = useCallback(
    async () => {
      const callable = httpsCallable(functions, 'auth-getListOfAuthAccounts');
      const returnData = (await callable()).data;      
      setAuthUserList(returnData as any[]);
    }, [functions]
  )
  React.useEffect(() => {
    getUserList()
  }, [getUserList]);

  const refreshAuthlist = async () => {
    await getUserList()
  }
  React.useEffect(() => {
    if (!location || !location.pathname) { return; }
    if (location.pathname === '/settings/support') {
      setTitle('Support');
      setActiveMenu('support');
    } else if (location.pathname.startsWith('/settings/venues')) {
      setTitle('Venue List');
      setActiveMenu('venues');
    } else if (location.pathname.startsWith('/settings/users')) {
      setTitle('User List');
      setActiveMenu('users');
    } else {
      setTitle('My Account');
      setActiveMenu('myaccount');
    }
  },[location])
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <List component="nav" sx={{height: '100%'}}>
              <ListItemButton onClick={() => {navigate('/dashboard')}}>
                <ListItemIcon>
                  <Home />
                </ListItemIcon>
                <ListItemText primary="Dashboard Home" />
              </ListItemButton>
              <AdminMenuButton
                Icon={<PersonIcon />}
                itemName='myaccount'
                label='My Account'
                activeMenu={activeMenu}
                navigate={navigate}
              />
              {currentManager && isVenueAdmin(currentManager) ? 
                <>
                <AdminMenuButton
                  Icon={<MapsHomeWorkIcon />}
                  itemName='venues'
                  label='Venues'
                  activeMenu={activeMenu}
                  navigate={navigate}
                />
                <AdminMenuButton
                  Icon={<PeopleIcon />}
                  itemName='users'
                  label='Users'
                  activeMenu={activeMenu}
                  navigate={navigate}
                />
                </>
                : <></>
              }
              <AdminMenuButton
                  Icon={<HelpIcon />}
                  itemName='support'
                  label='Contact Support'
                  activeMenu={activeMenu}
                  navigate={navigate}
                />
            </List>
            <List component="nav">
              <ListItemButton onClick={() => {auth.signOut(); navigate('/'); }}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </div>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            flexDirection: 'column'
          }}
        >
          <Toolbar />
          <div style={{ height: 'calc(100vh - 128px)', minHeight: '600px', margin: '16px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Routes>
                  <Route path="myaccount" element={<MyAccount />} />
                  <Route path="support" element={<SupportPage />} />
                  <Route path="venues" element={<VenueSettings />} /> 
                  <Route path="users" element={<UserSettings refreshAuthlist={refreshAuthlist} authUserList={authUserList} /> } /> 
                  <Route path="users/:userId" element={<UserDetail authUserList={authUserList} />} />
                  <Route path="*" element={<Navigate to="myaccount" />} />
                </Routes>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
